import { Link } from 'gatsby'
import React from 'react'

const isPartiallyActive = ({
  isPartiallyCurrent
}) => {
  return isPartiallyCurrent
    ? { className: "active" }
    : null
}

const PartialNavLink = props => (
  <Link getProps={isPartiallyActive} {...props} />
)

export default PartialNavLink
