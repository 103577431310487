import React from 'react'

import Header from './header'

import './css/normalize.css'
import './css/main.scss'

const Layout = ({ children }) => (
  <>
    <Header />

    <>
      <div id="wrapper">
        {children}
      </div>
    </>
  </>
)

export default Layout
