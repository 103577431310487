import { Link } from 'gatsby'
import React from 'react'

import PartialNavLink from './PartialNavLink'

import Logo from '../svg/logo.svg'
import Dev from '../svg/dev.svg'

const Header = () => (
  <header>
    <div className="logo">
      <h1>
        <a href="https://stratumn.com"><Logo className="logotype" /></a>
        <Link to="/"><Dev className="dev" /></Link>
      </h1>
    </div>

    <input class="menu-btn" type="checkbox" id="menu-btn" />
    <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
      <ul class="menu">
        <li><PartialNavLink to="/doc/">Developer SDK</PartialNavLink></li>
        {/* <li><Link activeClassName="active" to="/resources/">Resources</Link></li>*/}
        <li><PartialNavLink to="/community/">Community</PartialNavLink></li>
      </ul>
  </header>
)

export default Header
